import React from 'react';
import { Link } from 'react-router-dom';
import customRoute from '../CustomRoutes';

export const ProductTitle = (props) => {
  return (
    <div>
        <section className="single-product-banner">
            <div className="banner-img">
                <img src={props.image} className="img-fluid" alt=""/>
            </div>
            <div className="container spb-content">
                <div className="row">
                    <div className="col-md-6 offset-md-6">
                        <div className="banner-content">
                            <h1 className='product-title pt-50'>{props.title}</h1>
                            <p>{props.desc}</p>
                            <Link to={customRoute.contact.url} className="btn btn-accent mt-3 me-4">Get a FREE Estimate</Link>
                            <a onClick={()=>props.handleClick("OPTION")} className="btn btn-dark-outline mt-3">See Options</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</div>
  )
}
