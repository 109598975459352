import React,{useContext,useRef} from 'react';
import PhoneInput from "react-phone-input-2"; 
import "react-phone-input-2/lib/style.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import AppContext from '../AppContext';
import { ToastMessage } from './ToastMessage';
import { useNavigate } from 'react-router';
import customRoute from './CustomRoutes';
import {Hourglass} from 'react-loader-spinner';

export const Estimateform = ({dealer}) => {
    const {project,dealerStatus} = useContext(AppContext)
    const [name,setName]=React.useState('');
    const [email,setEmail]=React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const [desc,setDesc] = React.useState("");
    const [proNeeds,setProNeeds] = React.useState(null)
    const [validated, setValidated] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [button,setButton] = React.useState(false)
    const [zipCode, setZipCode] = React.useState("");
    const [apiMessage,setapiMessage] = React.useState("")
    const [dialCode,setDialCode] = React.useState("")
    const [id,setId] = React.useState("")
    const navigate=useNavigate()
    const [timer, setTimer] = React.useState(null)
    
    const reset=()=>{
        setName("")
        setEmail("")
        setPhoneNumber("")
        setDesc("")
        setProNeeds(null)
        setValidated(false)
        setZipCode("")
        setButton(false)
    }
    const handleForm =(e)=>{
        setButton(true)
        const form = e.currentTarget;
        let contact=phoneNumber?.replace(`+${dialCode}`,"")
        contact=contact.includes("+")?contact.replace("+",""):contact
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else if(contact.length==0||contact.length<7){
            e.stopPropagation();
            setapiMessage("Contact number is required field!")
            setShow(true)
        } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,5}$/.test(email)){
            e.stopPropagation();
            setapiMessage("Entered Email id is invalid!")
            setShow(true)
        } else if(zipCode&&zipCode.length<5){
            e.stopPropagation();
            setapiMessage("Entered Zipcode is invalid!")
            setShow(true)
        } else{
            axios.post(process.env.REACT_APP_API_URL+"api/dealer_crm_lead",{
                name: name,
                email: email,
                phone_no: "+1"+phoneNumber,
                project_id: proNeeds,
                dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
                note_description: desc,
                type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
                zipcode:zipCode,
                form:"Top",
                id:id
            },{
                headers: {
                    "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin":" *",
                    authtoken: process.env.REACT_APP_API_TOKEN
                }
            })
            .then((res)=>{
                if([200,201].includes(res.status)){
                    setapiMessage("Thank you for submitting you information, and we will get back to you soon.")
                    setShow(true)
                    reset()
                    if(window.location.pathname.includes("/products")){
                        window.location.replace(customRoute.thank_you_4.url)
                    }else{
                        window.location.replace(customRoute.thank_you_1.url)
                    }
                }else{
                    setShow(true)
                    setButton(false)
                }
            })
            .catch((res)=>{
                if(res.response.data.messages.error?.email){
                    setapiMessage(res.response.data.messages.error?.email ? res.response.data.messages.error.email:"")
                }else if(res.response.data.messages.error?.phone_no){
                    setapiMessage(res.response.data.messages.error?.phone_no &&res.response.data.messages.error?.phone_no=="Phone is Required" ? "Phone number is required field":"")
                }
                // setapiMessage(res.response.data.messages.error.email?res.response.data.messages.error.email:"")
                setShow(true)
                setButton(false)
            })
        }
    }

    const handlecall=()=>{
        clearTimeout(timer)
        const delayDebounceFn = setTimeout(() => {
            let payload={
                name: name,
                email: email,
                phone_no: "+1"+phoneNumber,
                project_id: proNeeds,
                dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
                note_description: desc,
                type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
                zipcode:zipCode,
                form:"Top"
            }
            if(id!=""){
                payload={
                    ...payload,
                    id:id
                }
            }
            axios.post(process.env.REACT_APP_API_URL+"api/dealer_capture_leads",{...payload},{
                headers: {
                    "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin":" *",
                    authtoken: process.env.REACT_APP_API_TOKEN
                }
            })
            .then((res)=>{
                console.log(res);
                if([200,201].includes(res.data.status)){
                    setId(res.data.id)
                }
            })
            .catch((res)=>{
                console.log(res);
            })
            
        }, 1000)

        setTimer(delayDebounceFn)
    }

  return (
    <>
        <Form validated={validated} id="contact-form" onSubmit={handleForm}>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group className="form-group">
                        <Form.Control type="text" name="full-name" className="form-control data-hj-allow" placeholder="Full Name*" value={name} onChange={(e)=>{
                            if(/^[A-Za-z]( ?[A-Za-z] ?)*$/g.test(e.target.value)){
                                setName(e.target.value)
                            }else if(e.target.value==""){
                                setName(e.target.value)
                            }
                            if(dealerStatus){
                                handlecall()
                            }
                        }} required />
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="form-group" id="estimate-form">
                        <PhoneInput country={"us"} name="phone" disableCountryCode={true} onlyCountries={["us"]} className="estimate-phone data-hj-allow" autoFormat={true} enableSearch={true} placeholder='Phone*' value={phoneNumber} onChange={(e,value)=>{
                            setPhoneNumber(e)
                            setDialCode(value.dialCode)
                            if(dealerStatus){
                                handlecall()
                            }
                        }} />
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group className="form-group">
                        <Form.Control type="email" name="email" className="form-control data-hj-allow" placeholder="E-mail*" value={email} onChange={(e)=>{
                            setEmail(e.target.value)
                            if(dealerStatus){
                                handlecall()
                            }
                        }} required />
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="form-group">
                        <Form.Control type="number" name="zip-code" className="form-control data-hj-allow" placeholder="Zip Code*" value={zipCode} onChange={(e)=>{
                            if(e.target.value.length<=5){
                                setZipCode(e.target.value)
                            }
                            if(dealerStatus){
                                handlecall()
                            }
                        }} required />
                    </Form.Group>
                </div>
                <div className="col-md-12">
                    <Form.Group className="form-group">
                        <Form.Select name="project-type"  id="" placeholder="Project Type*" className="form-control data-hj-allow" value={proNeeds} onChange={(e)=>{
                            setProNeeds(e.target.value)
                            if(dealerStatus){
                                handlecall()
                            }
                        }} required>
                            <option className='default-selection' value="" disabled selected>Project Type*</option>
                            {project.length>0&&project?.map((d,index)=>(
                                <option className='contact-option' key={index} value={d.id}>{d.title}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="col-lg-12 estimate-form-submit justify-content-between align-items-center">
                    <ToastMessage position={"bottom-start"} show={show} setShow={setShow} message={apiMessage} />
                    <Form.Group className="form-group text-center">
                        <Button type="submit" disabled={button} className="btn btn-secondary">
                            Get a FREE Estimate{' '}<Hourglass
                                visible={button}
                                height="20"
                                width="20"
                                ariaLabel="hourglass-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                colors={['#306cce', '#72a1ed']}
                            />
                        </Button>
                    </Form.Group>
                </div>
            </div>
        </Form>
    </>
  )
}
