import React,{useContext} from 'react';
import { useRef } from 'react';
import { Topbar } from '../../Components/Topbar';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import UVProtection  from '../../assets/icons/uv-protection.svg';
import Weather  from '../../assets/icons/weather-protection.svg';
import EasyAdjust  from '../../assets/icons/easy-adjust.svg';
import EnergyCost  from '../../assets/icons/energy-cost.svg';
import { ContactInfo } from '../../Components/ContactInfo';
import { ProductTitle } from '../../Components/ProductComponents/ProductTitle';
import { ProductFeature } from '../../Components/ProductComponents/ProductFeature';
import { ProductFeatureIcon } from '../../Components/ProductComponents/ProductFeatureIcon';
import { CompanyBadge } from '../../Components/CompanyBadge';
import { TotalControl } from '../../Components/ProductComponents/TotalControl';
import { OptionsSection } from '../../Components/ProductComponents/OptionsSection';
import { ImageGallary2 } from '../../Components/ImageGallary2';
import { PageSectionHeader } from '../../Components/ProductComponents/PageSectionHeader';
import Helmet from 'react-helmet';
import AppContext from '../../AppContext';

export const ShadeScreen = () => {
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
    const features = useRef(null);
    const warraty = useRef(null);
    const control = useRef(null);
    const option = useRef(null);
    const gallery = useRef(null);
    const reqAnEstimate = useRef(null);
    const handleClick = (type) => {
        if(type=="FEATURES"){
            console.log("asdjsad");
            features.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="WARRANTY"){
            warraty.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="TOTAL CONTROL"){
            control.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="OPTION"){
            option.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="GALLERY"){
            gallery.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="REQUEST AN ESTIMATE"){
            reqAnEstimate.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>Shade Screens {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags.shade}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>

        <ProductTitle 
            image={require("../../assets/images/shade-banner.jpg")} 
            title="Shade Screens" 
            desc="These screens block 97% of the sun’s rays while also making a substantial dent in your energy costs." 
            handleClick={handleClick}
        />
        
        <PageSectionHeader
            dataset={["FEATURES","WARRANTY","TOTAL CONTROL","OPTIONS","GALLERY","REQUEST AN ESTIMATE"]}
            handleClick={handleClick}
        />
        
        <ProductFeatureIcon 
            dataset={[
                {
                    icon: UVProtection,
                    title: "UV Protection"
                },
                {
                    icon: EnergyCost,
                    title: "Reduced Energy Costs"
                },
                {
                    icon: EasyAdjust,
                    title: "Easily Adjustable"
                },
                {
                    icon: Weather,
                    title: "Weather Protection"
                }
            ]} 
        />

        <div className='feature-container' ref={features}>
            <ProductFeature
                dataset={{
                    title:"Features",
                    desc:"Fenetex Shade Screens can be used as weather screens in warmer climates. The tight mesh of the solar screen does a good job of keeping out wind-driven rain and mitigating the effects of windy days with very little impact on the outdoor experience.",
                    image:require("../../assets/images/features.jpg"),
                    features:[
                        {
                            title:"Energy Savings",
                            slug:"energy-savings",
                            desc:"Fenetex Retractable Shade Screens reduce energy costs by blocking up to 97% of the sun’s rays, providing comfort, financial benefits, and reducing environmental impact."
                        },
                        {
                            title:"Complete Control",
                            slug:"complete-control",
                            desc:"Motorized shade screens can be seamlessly integrated into smart home automation, offering remote operation and automatic deployment."
                        },
                        {
                            title:"Easy Adjustment",
                            slug:"easy-adjust",
                            desc:"The easiest problem to solve is the one that solves itself. This why all Fenetex screens are self-adjusting and self-correcting. All you need to do is press a button."
                        },
                        {
                            title:"Options",
                            slug:"Options",
                            desc:"Choose the installation method that best suits your situation. We offer tracks or cable-guided screen solutions."
                        },
                        {
                            title:"Dual Protection",
                            slug:"dual-protection",
                            desc:"Pair Fenetex Retractable Shade Screens with other Fenetex screens for versatile and independent protection adapting to any situation."
                        }
                    ]
                }}
            />
        </div>

    <div style={{marginTop: "-150px"}} ref={warraty}><CompanyBadge /></div>
    
    <div ref={control}>
        <TotalControl
            dataset={
                { 
                    title:"Total Control",
                    desc:"Our insect protection solution can be motorized to deploy at the touch of a button. For ultimate peace of mind, they can be paired with smart home automation systems that can be operated and monitored from anywhere in the world or even deploy on their own when disaster strikes",
                    image:require("../../assets/images/controlRemoto.png"),
                    features:[
                        {
                            title: "Remote Control with App",
                            slug: "remote-control",
                            desc: "Using Fenetex’s Bond Pro App, you can control your retractable screens, pergola roofs, lights, fans, and more without having to use a proprietary remote control for each device."
                        },
                        {
                            title: "Total control",
                            slug: "total-control",
                            desc: "We’ve successfully integrated our insect screens with all the major home automation brands. At first, we were pioneers, then we became experts."
                        },
                        {
                            title: "Easily Adjustable",
                            slug: "easily-adjustable",
                            desc: "Our beautiful handheld remote works with our Fenetex Smart Motors and can control up to 15 screens independently or an unlimited number of screens within 15 zones"
                        },
                        {
                            title: "Double Protection",
                            slug: "double-protection",
                            desc: "We stock three brands of motors that are used depending on customer’s needs and the application. All motors are specified when ordering screens though our online ordering portal."
                        }
                    ]
                }
            }
        />
    </div>

    <div ref={option}>
        <OptionsSection
            dataset={
                {
                    title:"Options",
                    option_categories:[
                        // {
                        //     title:"Nano 50",
                        //     options: require("../../assets/images/options/nano50.png")
                        // },
                        {
                            title:"Nano 97",
                            options: require("../../assets/images/options/nano97.png")
                        },
                        {
                            title:"Nano 95",
                            options: require("../../assets/images/options/nano95.png")
                        },
                        {
                            title:"Specialty Ame",
                            options: require("../../assets/images/options/specialty-ame.png")
                        },
                        {
                            title:"Dim Out",
                            options: require("../../assets/images/options/dimout.png")
                        },
                        {
                            title:"Textilene 90",
                            options: require("../../assets/images/options/textilene90.png")
                        },
                        {
                            title:"Textilene 95",
                            options: require("../../assets/images/options/textile95.png")
                        },
                        {
                            title:"Textilene 80",
                            options: require("../../assets/images/options/textilene80.png")
                        },
                        // {
                        //     title:"Serge Ferrari",
                        //     options: require("../../assets/images/options/sergeFerrari.png")
                        // },
                        {
                            title:"Shadeview",
                            options: require("../../assets/images/options/shadeview.png")
                        }
                    ]
                }
            }
        />
    </div>
    <div ref={gallery}>
        <ImageGallary2
            dataset={[
                {image:require("../../assets/images/5-2.png")},
                {image:require("../../assets/images/01-1.png")},
                {image:require("../../assets/images/02-1.png")},
                {image:require("../../assets/images/03-1.png")},
                {image:require("../../assets/images/04-2.png")},
            ]}
        />
    </div>        
    <div ref={reqAnEstimate}>
        <ContactInfo />
    </div>
        <Footer />
    </div>
  )
}
