import React from 'react';

export const ProductFeatureIcon = (props) => {
  return (
    <div>
        <section className="screen-features">
            <div className="container">
                <div className="row text-center justify-content-md-around">
                    {props.dataset.map((data,index)=>(
                        <div className="col-md-3 col-sm-6">
                            <img src={data.icon} alt=""/>
                            <h3>{data.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </div>
  )
}
