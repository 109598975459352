import React from 'react';

export const ProductFeature = (props) => {
  return (
    <div>
        <section className="features-sec py-80">
            <div className="container">
                <div className="row pb-80">
                    <div className="col-md-6  order-2 order-md-1">
                        <div className="section-title-dark pt-80 mb-5">
                            <h2 className="heading mb-3">{props.dataset.title}</h2>
                            <p className="text-white" dangerouslySetInnerHTML={{__html: props.dataset.desc}}></p>
                        </div>
                        <div className="accordion accordion-flush" id="feature_accordion">
                            {props.dataset.features.map((data,index)=>(
                                <div className="accordion-item dark-accordion" key={index}>
                                    <h3 className="accordion-header">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={"#"+data.slug}>
                                        {data.title}
                                    </button>
                                    </h3>
                                    <div id={data.slug} className="accordion-collapse collapse" data-bs-parent="#feature_accordion">
                                    <p className="accordion-body">{data.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-md-6 order-1 order-md-2">
                        <div className="img-box">
                            <img src={props.dataset.image} width="" height="1034" className="img-fluid rounded-4" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
