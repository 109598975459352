import {Header} from '../Components/Header';
import {Topbar} from '../Components/Topbar';
import React,{useContext} from 'react';
import { Footer } from '../Components/Footer';
import '../Styles/techsolution.css';
import Helmet from 'react-helmet';
import AppContext from '../AppContext';
import { Link } from 'react-router-dom';
import customRoute from '../Components/CustomRoutes';

export const TechSolution = () => {
    const [activeImage,setActiveImage]=React.useState("1")
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)

    const handleImage =(active)=>{
        setActiveImage(active)
    }
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>Technology Solutions {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags?.tech_solution}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>
        <section className="tech-banner-section">
            <div className='overlay-text'>
                <div>
                    <b>Discover Fenetex's Screen</b><br />
                    Technology Solutions <br/>
                    <Link to={customRoute.contact.url} className="btn btn-accent mt-3">Get a FREE Estimate</Link>
                </div>
            </div>
            <div className='overlay-background' />
            <iframe className="background-video-embed" frameborder="0" allowfullscreen="" style={{width:"100%"}} loop allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Fenetex" width="640" height="360" src="https://www.youtube.com/embed/9St3T3fCXU4?controls=0&amp;autoplay=1&amp;loop=0&amp;rel=0&amp;playsinline=1&amp;enablejsapi=1&amp;origin=http%3A%2F%2Ffenetex.pisego.com&amp;widgetid=1" id="widget2"></iframe>    
        </section>

        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-left">
                            <div className="section-title-light">
                                <h2>Our welding process</h2>
                            </div>
                            <p className='tech-processing-para'>Fenetex keder is an assembly where a PVC cord is overwrapped by a low-friction PVC-coated fabric that can be attached to a screen edge.</p>
                            <div className='text-center'>
                                {activeImage=="1"?(
                                    <img src={require("../assets/images/welding.png")} className="img-fluid" alt=""/>
                                ):activeImage=="2"?(
                                    <img src={require("../assets/images/paso2-e1702094093448.png")} className="img-fluid" alt=""/>
                                ):activeImage=="3"?(
                                    <img src={require("../assets/images/paso3-e1702093949748.png")} className="img-fluid" alt=""/>
                                ):(
                                    <img src={require("../assets/images/paso4-e1702093988209.png")} className="img-fluid" alt=""/>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="tech-accordion accordion accordion-flush" id="accordion-style-two">
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-one" onClick={()=>handleImage("1")}>
                                    1. Perfecting Keder Performance Over a Decade
                                </button>
                                </h3>
                                <div id="faq-one" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body">
                                    <p>We’ve engineered our keder with specific cord hardness, fabric densities, and PVC coatings. Our formula for this has been perfected over a decade of manufacturing and real-world use to ensure the best possible performance.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-two" onClick={()=>handleImage("2")}>
                                    2. A High-Energy Joining Process
                                </button>
                                </h3>
                                <div id="faq-two" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body">
                                    <p>A precisely cut screen edge is inserted into Fenetex keder. Welding bars located above and below the screen will come together when the welding cycle is initiated.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-three" onClick={()=>handleImage("3")}>
                                    3. The Molecular-Level Fusion of Keder and Screen
                                </button>
                                </h3>
                                <div id="faq-three" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body">
                                    <p>When the welding cycle is initiated, the bars clamp down on the materials with a precise amount of pressure. Then, 10kW of RF energy is briefly pulsed through the material. This creates a lot of heat, welding the materials together. Subsequently, a cooling phase under pressure sets the weld.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-four" onClick={()=>handleImage("4")}>
                                    4. Backed by a Lifetime Warranty
                                </button>
                                </h3>
                                <div id="faq-four" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body">
                                    <p>Once the weld cycle is complete, the keder and screen are now permanently joined at the molecular level and cannot be separated. There are no zippers to break, no adhesives to fail, and no stitching to wear out. Our process is so reliable, our screens are backed by a lifetime warranty.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                                
                    </div>
                </div>
            </div>      
        </section>

        <section className='tech-desc-section'>
            <div className='container'>
                <div className='row mb-5 align-items-center'>
                    <div className='col-md-6 d-flex justify-content-center justify-content-sm-end'>
                        <div className='tech-section-img'>
                            <img src={require("../assets/images/Group-1000002923-e1705531164716.png")} width={"85%"} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='tech-desc'>
                            <h2>Control Options</h2>
                            <p className='tech-parent-desc'>Fenetex retractable screens – phone apps, home automation systems, by voice with Alexa or Google, or using a standard Fenetex remote control.</p>
                            <br/>
                            <div className='tech-sub-desc'>
                                <strong>01. Remote Control with App</strong>
                                <p className='tech-sub-para'>Using Fenetex’s Bond Pro App, you can control your retractable screens, pergola roofs, lights, fans and more without having to use a proprietary remote control for each device. Think of it as a universal remote for your house that you can operate from your phone – anywhere in the world. The App is compatible with most brand name devices using RF controls and home automation systems.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-5 align-items-center'>
                    <div className='col-md-6 order-2 order-md-1'>
                        <div className='tech-desc'>
                            <div className='tech-sub-desc'>
                                <strong>02. Home Automation Compatibility</strong>
                                <p className='tech-sub-para'>We’ve never met a home automation system that our screens didn’t get along with. Today, smart homes are pretty common, but this wasn’t always the case. Years ago, this was a novelty feature of ultra high-end homes, and very often these homes used Fenetex screens. We’ve successfully integrated our screens with all the major home automation brands. At first, we were pioneers, then we became experts.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 order-1 order-md-2'>
                        <div className='tech-section-img'>
                            <img src={require("../assets/images/DSC_0165_1-removebg.png")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>

                <div className='row mb-5 align-items-center'>
                    <div className='col-md-6'>
                        <div className='tech-section-img'>
                            <img src={require("../assets/images/DSC_0161-removebg-copy.png")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='tech-desc'>
                            <div className='tech-sub-desc'>
                                <strong>03. Fenetex Remote</strong>
                                <p className='tech-sub-para'>Our beautiful handheld remote works with our Fenetex Smart Motors and can control up to 15 screens independently or an unlimited number of screens within 15 zones, as long as they are within range of the remote</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="my-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="about-left">
                            <div className="section-title-light">
                                <h2 className="heading">Motor Options</h2>
                            </div>
                            <p>We stock three brands of motors—Somfy, Gaposa, and our own Fenetex smart motor—that are used depending on customers’ needs and the application. All motors are specified when ordering screens through our online ordering portal.</p>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <img src={require("../assets/images/motor_options.png")} className="img-fluid" alt=""/>
                    </div>               
                </div>
            </div>      
        </section>
        <Footer />     
    </div>
  )
}
