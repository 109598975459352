import React from 'react';

export const OptionsSection = (props) => {
    const [activeOption,setActiveOption]=React.useState(props.dataset.option_categories[0]);
  return (
    <div>
        <section className="product-options py-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div className="section-title-light mb-4">
                            <h2 className="heading">{props.dataset.title}</h2>
                        </div>
                        <ul className='option-tab-container'>
                            {props.dataset.option_categories.map((data,index)=>(
                                <li key={index} className={activeOption.title==data.title?"active-option-tab option-tab":"option-tab"} onClick={()=>setActiveOption(data)}>{data.title}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <img src={activeOption.options} style={{width:"-webkit-fill-available"}} className="me-2" alt=""/>
                        {/* <img src={require("../../assets/images/insect-nsu.png")} width="130" alt=""/> */}
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
