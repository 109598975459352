import React from 'react';
import {Link} from 'react-router-dom';
import customRoute from './CustomRoutes';

export const CompanyBadge = ({handleClickTop}) => {
  return (
    <div>
        <section className="lifetime-warranty mt-80 py-80 text-center">
            <div className="container-fluid mb-30">
                <div className="row">
                    <div className="col-12">
                        <img src={require("../assets/images/warranty.png")} alt=""/>
                        <h2 className="elementor-heading-title elementor-size-default">The <span>only company </span>to offer a lifetime warranty.</h2>
                        <p>Choose Fenetex for unmatched quality and confidence with our exceptional Lifetime Warranty.</p>
                        {/* <Link to={customRoute.contact.url} className="btn btn-accent">Request a Free Estimate</Link> */}
                        <a className="btn btn-accent" onClick={handleClickTop}>Request a FREE Estimate</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
