import React, { useState, useContext, cloneElement, createContext } from "react";

const callAll = (...fns) => (...args) => fns.forEach((fn) => fn && fn(...args));

const ModalContext = createContext();

function Modal(props) {
  const [isOpen, setIsOpen] = useState(false);
  return <ModalContext.Provider value={{ isOpen, setIsOpen }} {...props} />;
}

function ModalDismissButton({ children: child }) {
  const { setIsOpen } = useContext(ModalContext);

  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  });
}

function ModalOpenButton({ children: child }) {
  const { setIsOpen } = useContext(ModalContext);

  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(true), child.props.onClick),
  });
}

function ModalContentsBase(props) {
  const { isOpen, setIsOpen } = useContext(ModalContext);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal fade show" onClick={() => setIsOpen(false)} {...props} style={{ display: "block" }}>
      <div className="modal-dialog w-50 mw-100 modal-dialog-centered" role="document" onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
}

function ModalContents({ title, children, ...props }) {
  return (
    <ModalContentsBase {...props}>
      <div className="modal-content">
        <div className="modal-header justify-content-end border border-0 fs-2">
          <ModalDismissButton>
            <i className="fs-6 fa fa-close mx-3 mt-2" style={{lineHeight: '2rem'}}></i>
          </ModalDismissButton>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </ModalContentsBase>
  );
}

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
