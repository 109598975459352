import {Header} from '../Components/Header';
import {Topbar} from '../Components/Topbar';
import React from 'react';
import { Footer } from '../Components/Footer';
import "../Styles/fenetexhome.css";
import Background from '../assets/images/banner-scaled.jpg';
import { ContactInfo } from '../Components/ContactInfo';
import {CompanyBadge} from '../Components/CompanyBadge';
import { UnmatchedExSlider } from '../Components/UnmatchedExSlider';
import { Estimateform } from '../Components/Estimateform';
import { useRef } from 'react';
import { ProductsSection } from '../Components/ProductsSection';
import { Link } from "react-router-dom";
import { CustomiseOption } from '../Components/CustomiseOption';
import { WhyFenetex } from '../Components/WhyFenetex';
import customRoute from '../Components/CustomRoutes';

export const FenetexHome = (props) => {
    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
  return (
    <div className='fenetex-home'>
            <header className='header-bar'>
                <Topbar dealeropp={true} />
                <Header />
            </header>
            <section className="home-banner-section" ref={ref}>
                <div className="banner-bg">
                    <img src={Background} className="img-fluid banner-img" alt="" />
                    <div className="home-banner-overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="banner-content">
                                    <div className="banner-title">
                                        <h1>Retractable <br />screens for just <br/>about <span>everything</span><br /> you can imagine.</h1>
                                    </div>
                                    <div className="banner-left-form mt-3">
                                        <h3 className='text-center'>Transform Your Home Today!</h3>
                                        <Estimateform />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </section>

            <ProductsSection />

            <section className="home-about my-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-left mb-4">
                                <div className="section-title-light">
                                    <h2 className="heading">Revolutionize your outdoor space with Fenetex's retractable screens. Elevate comfort</h2>
                                </div>
                                <p>Discover unparalleled convenience and security with Fenetex screens. Our screens, renowned for their easy installation and adjustment on any structure, seamlessly blend strength, reliability, and durability. Fenetex's retractable screens cater to diverse needs—from patio and porch to screen doors, bug screens, folding doors, aluminum doors, patio doors, sliding doors, swing doors, swinging doors, motorized retractable screens, and retractable impact screens. Effortlessly hidden yet deployable with a touch, they integrate into smart home systems for remote operation, offering a cutting-edge solution tailored to your lifestyle.</p>
                                <a href="#" className="btn btn-accent mt-3">More Info</a>
                            </div>
                        </div> 
                        <div className="col-lg-6">
                            <img src={require("../assets/images/outdoor.jpg")} className="img-fluid" alt=""/>
                        </div>              
                    </div>
                </div>      
            </section>

            <div className=''>
                <CompanyBadge />
            </div>

            <WhyFenetex />

            <section className="request-estimate">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h3 className="text-end">Request a FREE estimate today</h3>
                        </div>
                        <div className="col-lg-5 text-center">
                            <div href="" onClick={handleClick}  className="btn btn-accent">Get a FREE Estimate</div>
                        </div>
                    </div>
                </div>
            </section>

            <CustomiseOption handleClick={handleClick} /> 

            {/* <UnmatchedExSlider /> */}
            <ContactInfo />
            <Footer handleClickTop={handleClick} />     
        </div>
  )
}
