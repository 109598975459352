import React from 'react';

export const HomeBanner = (props) => {
  return (
    <div>
        <section className="home-banner-section">
            <div className="banner-bg">
            <img src={props.banner_image} className="img-fluid banner-img" alt="" />
                <div className="home-banner-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="banner-content">
                                <div className="banner-title">
                                    <h1>Fenetex Screens for  <span>North Western Dallas</span></h1>
                                </div>
                                <div className="banner-left-form mt-3">
                                    <h3>Request a FREE Estimate</h3>
                                    <form action="">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Full Name"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="tel" className="form-control" placeholder="Phone"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder="Email"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select name="" id="" className="form-control">
                                                        <option value="">Project Type</option>
                                                        <option value="">Insect Screens</option>
                                                        <option value="">Shade Screens</option>
                                                        <option value="">Clear Vinyl</option>
                                                        <option value="">Hurricane Screens</option>
                                                        <option value="">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group text-end">
                                                    <button type="submit" className="btn btn-secondary">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </section>
    </div>
  )
}
