import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";


const IconBox = ({ title, description, icon, isOpen, toggle }) => {
  return (
    <>
    <div className="d-flex mb-4">
      <div onClick={toggle} style={{ cursor: "pointer" }} className="">
        <span className="icon">
          <img src={icon} alt="" />
        </span>
      </div>
      <div className="align-self-center m-2 d-flex justify-content-between w-100">
        <h3
          className="icon-box-title"
        
          style={{ cursor: "pointer" }}
        >
          <span>{title}</span>
        </h3>
        <div className=" ">
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} color="white"   onClick={toggle}/>
        </div>
      </div>
      </div>
      <div
        className={`icon-box-content  ${
          isOpen ? "collapse show" : "collapse"
        }`}
      >
        <p className="icon-box-description">{description}</p>
      
    </div>
    </>
  );
};

export default IconBox;
