import React,{useContext} from 'react';
import { useRef } from 'react';
import { Topbar } from '../../Components/Topbar';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import Weather  from '../../assets/icons/weather-protection.svg';
import Certified  from '../../assets/icons/certified.svg';
import BuiltLast  from '../../assets/icons/built-last.svg';
import { ContactInfo } from '../../Components/ContactInfo';
import { ProductTitle } from '../../Components/ProductComponents/ProductTitle';
import { ProductFeature } from '../../Components/ProductComponents/ProductFeature';
import { ProductFeatureIcon } from '../../Components/ProductComponents/ProductFeatureIcon';
import { CompanyBadge } from '../../Components/CompanyBadge';
import { TotalControl } from '../../Components/ProductComponents/TotalControl';
import { ImageGallary2 } from '../../Components/ImageGallary2';
import { PageSectionHeader } from '../../Components/ProductComponents/PageSectionHeader';
import Helmet from 'react-helmet';
import AppContext from '../../AppContext';

export const HurricaneScreen = () => {
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
    const features = useRef(null);
    const warraty = useRef(null);
    const control = useRef(null);
    const option = useRef(null);
    const gallery = useRef(null);
    const reqAnEstimate = useRef(null);
    const handleClick = (type) => {
        if(type=="FEATURES"){
            features.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="WARRANTY"){
            warraty.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="TOTAL CONTROL"){
            control.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="OPTION"){
            option.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="GALLERY"){
            gallery.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="REQUEST AN ESTIMATE"){
            reqAnEstimate.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>Hurricane Screens {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags?.hurrican}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>

        <ProductTitle 
            image={require("../../assets/images/banner-2.jpg")} 
            title="Hurricane Screens" 
            desc="Our hurricane screens are the backbone of our whole product line. We developed them to be strong, attractive, UV-resistant, and ultimately reliable." 
            handleClick={handleClick}
        />
        
        <PageSectionHeader
            dataset={["FEATURES","WARRANTY","TOTAL CONTROL","OPTIONS","GALLERY","REQUEST AN ESTIMATE"]}
            handleClick={handleClick}
        />
        
        <ProductFeatureIcon 
            dataset={[
                {
                    icon: Certified,
                    title: "Certified Strong"
                },
                {
                    icon: BuiltLast,
                    title: "Built to Last"
                },
                {
                    icon: Weather,
                    title: "Weather Protection"
                }
            ]} 
        />

        <div className='feature-container' ref={features}>
            <ProductFeature
                dataset={{
                    title:"Features",
                    desc:"They were our first retractable screen product, and continue to be the foundation of everything we’ve done since. Screens built for hurricanes are some tough screens indeed. Fenetex Hurricane Screens are aramid reinforced so <strong>strong that they are certified as hurricane shutters for use anywhere in Florida.</strong>",
                    image:require("../../assets/images/hurricane-01-2.png"),
                    features:[
                        {
                            title:"UV Resistant",
                            slug:"UV-resistant",
                            desc:"The fabric used in our Hurricane Screens blocks up to 97% of the sun’s UV rays and offers protection from wind, rain, insects, dust, and debris."
                        },
                        {
                            title:"Certified Strength",
                            slug:"Certified-strength",
                            desc:"Our Hurricane Screens go through all testing and certification to qualify as hurricane shutters for the most demanding agencies and locales, including the High Velocity Hurricane Zone of Miami-Dade and Broward Counties."
                        },
                        {
                            title:"Protective Perimeter",
                            slug:"Protective-perimeter",
                            desc:"When you have Fenetex Hurricane Screens installed on your patio, getting ready for a storm event is as easy as pushing a button or pulling up an app to close your screens. All the furniture and openings within the patio area will be protected."
                        },
                        {
                            title:"Perfect Fit",
                            slug:"Perfect-fit",
                            desc:"Like all Fenetex products, our Hurricane Screens are built to order and made to fit your openings exactly. We utilize robotic cutting for fabrics and metal and computer-controlled machines for a perfect finish every time."
                        },
                        {
                            title:"Optimal Combination",
                            slug:"Optimal-combination",
                            desc:"Fenetex Retractable Hurricane Screens can be paired with our other screens offering independent and customized protection when needed."
                        }
                    ]
                }}
            />
        </div>

        <div style={{marginTop: "-150px"}} ref={warraty}><CompanyBadge /></div>

        <div ref={control}>
            <TotalControl
                dataset={
                    { 
                        title:"Total Control",
                        desc:"Our insect protection solution can be motorized to deploy at the touch of a button. For ultimate peace of mind, they can be paired with smart home automation systems that can be operated and monitored from anywhere in the world or even deploy on their own when disaster strikes",
                        image:require("../../assets/images/controlRemoto.png"),
                        features:[
                            {
                                title: "Remote Control with App",
                                slug: "remote-control",
                                desc: "Using Fenetex’s Bond Pro App, you can control your retractable screens, pergola roofs, lights, fans, and more without having to use a proprietary remote control for each device."
                            },
                            {
                                title: "Total control",
                                slug: "total-control",
                                desc: "We’ve successfully integrated our screens with all the major home automation brands. At first, we were pioneers, then we became experts."
                            },
                            {
                                title: "Easily Adjustable",
                                slug: "easily-adjustable",
                                desc: "Our beautiful handheld remote works with our Fenetex Smart Motors and can control up to 15 screens independently or an unlimited number of screens within 15 zones"
                            },
                            {
                                title: "Double Protection",
                                slug: "double-protection",
                                desc: "We stock three brands of motors that are used depending on customer’s needs and the application. All motors are specified when ordering screens though our online ordering portal."
                            }
                        ]
                    }
                }
            />
        </div>

        <section className="product-options py-80" ref={option}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-2" />
                    <div className="col-md-4">
                        <div className="section-title-light mb-4">
                            <h2 className="heading">Options</h2>
                        </div>
                        <p>We use Twitchell OmegaTex fabric for our hurricane screens. Available in four colors, this UV resistant fabric screen is engineered with scientifically enhanced yarns, the very same aramid fibers used to make bulletproof vests. Our hurricane screens allow you to see out while blocking 91% of the sun’s rays and protecting you from 150 MPH winds.</p>
                    </div>
                    {/* <div className="col-md-1" /> */}
                    <div className="col-md-4">
                        <img src={require("../../assets/images/hurracane2.png")} style={{width:"-webkit-fill-available"}} className="me-2" alt=""/>
                    </div>
                    <div className="col-md-2" />
                </div>
            </div>
        </section>
        
        <div ref={gallery}>
            <ImageGallary2
                dataset={[
                    {image:require("../../assets/images/gallery-1.jpg")},
                    {image:require("../../assets/images/gallery-2.jpg")},
                    {image:require("../../assets/images/gallery-3.jpg")},
                    {image:require("../../assets/images/gallery-4.jpg")},
                    {image:require("../../assets/images/gallery-5.jpg")},
                    {image:require("../../assets/images/gallery-6.png")},
                    {image:require("../../assets/images/gallery-7.png")},
                    {image:require("../../assets/images/gallery-8.png")},
                    {image:require("../../assets/images/gallery-9.png")},
                ]}
            />
        </div>
        <div ref={reqAnEstimate}>
            <ContactInfo />
        </div>
        <Footer />
    </div>
  )
}
