import React from 'react';

export const RevolutonSection = (props) => {
  return (
    <div>
        <section className="home-about my-80">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-left">
                            <div className="section-title-light">
                                <h2 className="heading">{props.title}</h2>
                            </div>
                            <p>{props.desc}</p>
                            <a href="#" className="btn btn-accent mt-3">More Info</a>
                        </div>
                    </div>               
                </div>
            </div>      
        </section>
    </div>
  )
}
