import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Pagination } from 'swiper/modules';

export const ImageGallary2 = (props) => {
  return (
    <div>
        <section className="py-100 gallary-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title-dark gallary-title d-flex align-items-center justify-content-between">
                            <h2 className='mb-3'>Gallery</h2>
                            <div>
                                <div className="swiper-button-prev mb-0"></div>
                                <div className="swiper-button-next mb-0"></div>
                            </div>
                        </div>
                        
                        <div className="showcase-slider-wrapper">
                            <Swiper
                                modules={[Navigation, A11y, Pagination]}
                                spaceBetween={10}
                                slidesPerView={3}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                            >
                                {props.dataset.map((data,index)=>(
                                    <SwiperSlide key={index}><img src={data.image} className="img-fluid" alt=""/></SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
