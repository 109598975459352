import React from 'react';

export const AboutSection = (props) => {
  return (
    <div>
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src={props.image} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="about-left">
                            <div className="section-title-light">
                                <h3 className="heading">{props.title}</h3>
                            </div>
                            <p>{props.desc}</p>
                        </div>
                    </div>
                    
                </div>
            </div>      
        </section>
    </div>
  )
}
