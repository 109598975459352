import React,{useContext,useRef} from 'react';
import { Topbar } from '../Components/Topbar';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import ContactImg from '../assets/images/contact-image.jpg';
import 'intl-tel-input/build/css/intlTelInput.css';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import PhoneInput from "react-phone-input-2"; 
import "react-phone-input-2/lib/style.css";
import { ToastMessage } from '../Components/ToastMessage';
import customRoute from '../Components/CustomRoutes';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../Styles/contact.css';
import '../Styles/style.css';
import AppContext from '../AppContext'
import { useNavigate } from 'react-router';
import Helmet from 'react-helmet';
import {Hourglass} from 'react-loader-spinner';

export const Contact = () => {
  const {dealer,project,dealerData,dealerStatus} = useContext(AppContext)
    const [name,setName]=React.useState('');
    const [email,setEmail]=React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [desc,setDesc] = React.useState("");
    const [proNeeds,setProNeeds] = React.useState("")
    const [validated, setValidated] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [button,setButton] = React.useState(false)
    const [apiMessage,setapiMessage] = React.useState("")
    const [zipCode,setZipCode] = React.useState("")
    const [dialCode,setDialCode] = React.useState("")
    const [timer, setTimer] = React.useState(null)
    const [id,setId] = React.useState("")
    const contactform = useRef();
    const navigate=useNavigate()
    const reset=()=>{
      setName("")
      setEmail("")
      setPhoneNumber("")
      setDesc("")
      setProNeeds("")
      setValidated(false)
      setZipCode("")
      setButton(false)
  }
  const handleForm =(e)=>{
      setButton(true)
      const form = e.currentTarget;
      let contact=phoneNumber?.replace(`+${dialCode}`,"")
      contact=contact.includes("+")?contact.replace("+",""):contact
      e.preventDefault();
      if (form.checkValidity() === false) {
          e.stopPropagation();
      } else if(contact.length==0||contact.length<7){
          e.stopPropagation();
          setapiMessage("Contact number is required field!")
          setShow(true)
      } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,5}$/.test(email)){
          e.stopPropagation();
          setapiMessage("Entered Email id is invalid!")
          setShow(true)
      } else if(zipCode&&zipCode.length<5){
          e.stopPropagation();
          setapiMessage("Entered Zipcode is invalid!")
          setShow(true)
      } else{
          axios.post(process.env.REACT_APP_API_URL+"api/dealer_crm_lead",{
              name: name,
              email: email,
              phone_no: "+1"+phoneNumber,
              project_id: proNeeds,
              dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
              note_description: desc,
              zipcode:zipCode,
              type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
              form:"Contact",
              id:id
          },{
              headers: {
                  "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                  "Content-type": "application/json; charset=UTF-8",
                  "Access-Control-Allow-Origin":" *",
                  authtoken: process.env.REACT_APP_API_TOKEN
              }
          })
          .then((res)=>{
            if([200,201].includes(res.status)){
                setapiMessage("Thank you for submitting you information, and we will get back to you soon.")
                setShow(true)
                reset()
                window.location.replace(customRoute.thank_you_3.url)
            }else{
                setShow(true)
                setButton(false)
            }
          })
          .catch((res)=>{
            if(res.response.data.messages.error?.email){
              setapiMessage(res.response.data.messages.error?.email ? res.response.data.messages.error.email:"")
            }else if(res.response.data.messages.error?.phone_no){
                setapiMessage(res.response.data.messages.error?.phone_no &&res.response.data.messages.error?.phone_no=="Phone is Required" ? "Phone number is required field":"")
            }
            // setapiMessage(res.response.data.messages.error.email?res.response.data.messages.error.email:"")
            setShow(true)
            setButton(false)
          })
      }
  }

  const handlecall=()=>{
    clearTimeout(timer)
    const delayDebounceFn = setTimeout(() => {
        let payload={
            name: name,
            email: email,
            phone_no: "+1"+phoneNumber,
            project_id: proNeeds,
            dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
            note_description: desc,
            type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
            zipcode:zipCode,
            form:"Top",
            id:id
        }
        if(id!=""){
            payload={
                ...payload,
                id:id
            }
        }
        axios.post(process.env.REACT_APP_API_URL+"api/dealer_capture_leads",{...payload},{
            headers: {
                "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin":" *",
                authtoken: process.env.REACT_APP_API_TOKEN
            }
        })
        .then((res)=>{
            console.log(res);
            if([200,201].includes(res.data.status)){
                setId(res.data.id)
            }
        }).catch((res)=>{
          console.log(res);
      })
        
    }, 1000)

    setTimer(delayDebounceFn)
  }
  
  return (
    <div>
      {dealerStatus&&(<Helmet>
        <title>Contact Us {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
        <meta name='description' content={`${dealerData?.meta_tags?.contact}`} />
      </Helmet>)}
      <header className='header-bar'>
          <Topbar dealeropp={true} />
          <Header />
      </header>
      <section className='contact-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='contact-title text-center'>Get in Touch</h2>
              <p className='contact-sub-title text-center'>Get your FREE estimate!</p>
            </div>
          </div>
          <div className='row align-items-center my-5'>
            <div className='col-md-6'>
              <Form validated={validated} onSubmit={handleForm} id="contact-form" className='mb-3'>
                <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Control type="text" name="full-name" className="form-control data-hj-allow" placeholder="Full Name*" value={name} onChange={(e)=>{
                        if(/^[A-Za-z]( ?[A-Za-z] ?)*$/g.test(e.target.value)){
                          setName(e.target.value)
                        }else if(e.target.value==""){
                            setName(e.target.value)
                        }
                        if(dealerStatus){
                          handlecall()
                        }
                      }} required />
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Control type="email" name="email" className="form-control data-hj-allow" placeholder="E-mail*" value={email} onChange={(e)=>{
                        setEmail(e.target.value)
                        if(dealerStatus){
                          handlecall()
                        }
                      }} required />
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-6 form-group">
                      <PhoneInput country={"us"} name="phone" onlyCountries={["us"]} placeholder='Phone*' disableCountryCode={true} className="estimate-phone data-hj-allow" value={phoneNumber} onChange={(e,value)=>{
                        setPhoneNumber(e)
                        setDialCode(value.dialCode)
                        if(dealerStatus){
                          handlecall()
                        }
                      }} />
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group">
                        <Form.Control type="number" name="phone" className="form-control data-hj-allow" placeholder="Zip Code*" value={zipCode} onChange={(e)=>{
                          if(e.target.value.length<=5){
                            setZipCode(e.target.value)
                          }
                          if(dealerStatus){
                            handlecall()
                          }
                        }} />
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Select name="project-type" id="" className="form-control data-hj-allow" value={proNeeds} onChange={(e)=>{
                        setProNeeds(e.target.value)
                        if(dealerStatus){
                          handlecall()
                        }
                      }} required>
                          <option className='contact-option' value="" disabled>Project Type*</option>
                          {project.length>0&&project?.map((d,index)=>(
                              <option className='contact-option' key={index} value={d.id}>{d.title}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <textarea name="" id="" cols="30" rows="4" className="form-control data-hj-allow" placeholder="Brief description of your project" value={desc} onChange={(e)=>{
                        setDesc(e.target.value)
                        if(dealerStatus){
                          handlecall()
                        }
                      }}></textarea>
                    </Form.Group>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center contact-form-submit">
                    <Form.Group>
                      <Button type='submit' disabled={button} className="mb-3 btn btn-accent">
                        Get a FREE Estimate {' '}
                        <Hourglass
                            visible={button}
                            height="20"
                            width="20"
                            ariaLabel="hourglass-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={['#306cce', '#72a1ed']}
                        />
                      </Button>
                    </Form.Group>
                    <div className='d-flex justify-content-end'>
                      <ToastMessage position={"bottom-end"} show={show} setShow={setShow} message={apiMessage} />
                    </div>
                  </div>
                </div>
              </Form> 
            </div>
            <div className='col-md-6'>
              <img src={ContactImg} className='img-fluid'/>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-get-in-touch py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2 className='contact-title'>Get in Touch</h2>
              <p className='contact-sub-title'>Feel free to contact us using any of the methods listed. We are always happy to hear from our clients and visitors, and will do our best to respond to your inquiry as soon as possible.</p>
              <p className='contact-sub-title'>Please don’t hesitate to reach out to us by phone or email. Thank you for considering Fenetex for your screen solutions!</p>
            </div>
            <div className='col-md-6 contact-detail'>
              <div className='widgets-section'>
                <div className='widget-heading'>
                  <h2>Phone</h2>
                  <div className='widget-content'>
                  <a href={`tel:9044375168`}><p>800 FENETEX<br />904 437 5168</p></a>
                  </div>
                </div>
              </div>
              <div className='widgets-section'>
                <div className='widget-heading'>
                  <h2>E-Mail</h2>
                  <div className='widget-content'>
                    <p>info@fenetex.com</p>
                  </div>
                </div>
              </div>
              <div className='widgets-section'>
                <div className='widget-heading'>
                  <h2>Address</h2>
                  <div className='widget-content'>
                    <p>259 Ellis Road S, <br />Jacksonville, FL 32254</p>
                  </div>
                </div>
              </div>
              <div className='widgets-section'>
                <div className='widget-heading'>
                  <h2>Follow us on social media</h2>
                  <div className='widget-content'>
                    <ul>
                      <li>
                        <a className="" href="https://www.facebook.com/Fenetex" target="_blank">
						              <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="" href="http://www.linkedin.com/company/fenetex" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a className="" href="https://www.instagram.com/fenetex/" target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
