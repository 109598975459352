import React from 'react'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export const ToastMessage = ({show,setShow,message,position}) => {
  return (
    <ToastContainer position={position} className="" containerPosition="relative" style={{ zIndex: 1,width:"100%" }}>
        <Toast onClose={() => setShow(false)} show={show} style={{width:"100%"}}>
          <Toast.Body>
            <div className='d-flex align-items-center justify-content-between'>
              <div>{message}</div>
              <div className='cursor-pointer' onClick={()=>setShow(false)}><i className='fa fa-close' /></div>
            </div>
          </Toast.Body>
        </Toast>
    </ToastContainer>
  )
}
