import React,{useContext} from 'react';
import { useRef } from 'react';
import { Topbar } from '../../Components/Topbar';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import ClearView  from '../../assets/icons/clear-view.svg';
import Weather  from '../../assets/icons/weather-protection.svg';
import EasyAdjust  from '../../assets/icons/easy-adjust.svg';
import BuiltLast  from '../../assets/icons/built-last.svg';
import { ContactInfo } from '../../Components/ContactInfo';
import { ProductTitle } from '../../Components/ProductComponents/ProductTitle';
import { ProductFeature } from '../../Components/ProductComponents/ProductFeature';
import { ProductFeatureIcon } from '../../Components/ProductComponents/ProductFeatureIcon';
import { CompanyBadge } from '../../Components/CompanyBadge';
import { TotalControl } from '../../Components/ProductComponents/TotalControl';
import { ImageGallary2 } from '../../Components/ImageGallary2';
import { PageSectionHeader } from '../../Components/ProductComponents/PageSectionHeader';
import { OptionsSection } from '../../Components/ProductComponents/OptionsSection';
import Helmet from 'react-helmet';
import AppContext from '../../AppContext';

export const ClearVinyl = () => {
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
    const features = useRef(null);
    const warraty = useRef(null);
    const control = useRef(null);
    const option = useRef(null);
    const gallery = useRef(null);
    const reqAnEstimate = useRef(null);
    const handleClick = (type) => {
        if(type=="FEATURES"){
            features.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="WARRANTY"){
            warraty.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="TOTAL CONTROL"){
            control.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="OPTIONS"){
            option.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="GALLERY"){
            gallery.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="REQUEST AN ESTIMATE"){
            reqAnEstimate.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>Clear Vinyl {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags?.clear_vinyl}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>

        <ProductTitle 
            image={require("../../assets/images/banner-1.jpg")} 
            title="Clear Vinyl" 
            desc="These screens maintain the wide-open view you want from your outdoor space, while protecting you from plunging temps or pouring rain." 
            handleClick={handleClick}
        />
        
        <PageSectionHeader
            dataset={["FEATURES","WARRANTY","TOTAL CONTROL","OPTIONS","GALLERY","REQUEST AN ESTIMATE"]}
            handleClick={handleClick}
        />
        
        <ProductFeatureIcon 
            dataset={[
                {
                    icon: ClearView,
                    title: "Clear View"
                },
                {
                    icon: BuiltLast,
                    title: "Built to Last"
                },
                {
                    icon: Weather,
                    title: "Weather Protection"
                },
                {
                    icon: EasyAdjust,
                    title: "Easily Adjustable"
                }
            ]} 
        />

    <div className='feature-container' ref={features}>
        <ProductFeature
            dataset={{
                title:"Features",
                desc:"When you want to be outdoors, but the temperatures are less than encouraging, Fenetex Clear Weather Screens solve the problem. Fenetex’s wide-angle view screen is made from clear vinyl with borders of solid vinyl.<strong> This is our most popular solution to keep patios useable in cold climates.</strong>",
                image:require("../../assets/images/features-1.jpg"),
                features:[
                    {
                        title:"Unobstructed Views",
                        slug:"unobstructed-views1",
                        desc:"Fenetex’s unobstructed wide windows provide customers with a clear and uninterrupted view of the surrounding scenery, ensuring a captivating experience."
                    },
                    {
                        title:"Built to Endure",
                        slug:"built-endure1",
                        desc:"Our clear vinyl weather screens are crafted with the same durability as our heavy-duty hurricane screens. With welded end retention, they offer reliable support even in windy conditions."
                    },
                    {
                        title:"Dual Protection",
                        slug:"dual-protection1",
                        desc:"Pair Clear Weather Screens with other Fenetex screens for versatile and independent protection adapting to any situation."
                    },
                    {
                        title:"Total Control",
                        slug:"total-control1",
                        desc:"Motorized deployment and integration with smart home automation allow for convenient operation and monitoring from anywhere. Our clear vinyl weather curtains can even deploy automatically."
                    },
                    {
                        title:"Easily Adjustable",
                        slug:"easily-adjustable1",
                        desc:"Fenetex Retractable Clear Weather Screens feature an adjustable track system, ensuring a perfect fit. They adapt to changing temperatures and provide long- lasting performance."
                    }
                ]
            }}
        />
    </div>
    
    <div style={{marginTop: "-150px"}} ref={warraty}><CompanyBadge /></div>
    <div ref={control}>
        <TotalControl
            dataset={
                { 
                    title:"Total Control",
                    desc:"Our insect protection solution can be motorized to deploy at the touch of a button. For ultimate peace of mind, they can be paired with smart home automation systems that can be operated and monitored from anywhere in the world or even deploy on their own when disaster strikes",
                    image:require("../../assets/images/controlRemoto.png"),
                    features:[
                        {
                            title: "Remote Control with App",
                            slug: "remote-control",
                            desc: "Using Fenetex’s Bond Pro App, you can control your retractable screens, pergola roofs, lights, fans, and more without having to use a proprietary remote control for each device."
                        },
                        {
                            title: "Total control",
                            slug: "total-control",
                            desc: "We’ve successfully integrated our screens with all the major home automation brands. At first, we were pioneers, then we became experts."
                        },
                        {
                            title: "Easily Adjustable",
                            slug: "easily-adjustable",
                            desc: "Our beautiful handheld remote works with our Fenetex Smart Motors and can control up to 15 screens independently or an unlimited number of screens within 15 zones"
                        },
                        {
                            title: "Double Protection",
                            slug: "double-protection",
                            desc: "We stock three brands of motors that are used depending on customer’s needs and the application. All motors are specified when ordering screens though our online ordering portal."
                        }
                    ]
                }
            }
        />
    </div>

    <div ref={option}>
    <OptionsSection
            dataset={
                {
                    title:"Options",
                    option_categories:[
                        {
                            title:"SERGE FERRARI",
                            options: require("../../assets/images/options/options-vinil.png")
                        },
                    ]
                }
            }
        />
    </div>

    <div ref={gallery}>
        <ImageGallary2
            dataset={[
                {image:require("../../assets/images/gallery-1.jpg")},
                {image:require("../../assets/images/gallery-3.jpg")},
                {image:require("../../assets/images/gallery-4.jpg")},
                {image:require("../../assets/images/gallery-5.jpg")},
                {image:require("../../assets/images/gallery-2.jpg")},
                {image:require("../../assets/images/11.jpg")},
            ]}
        />
    </div>
    <div ref={reqAnEstimate}>
        <ContactInfo />
    </div>
        <Footer />
    </div>
  )
}
