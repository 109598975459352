import React from 'react';
import IconUSA from "../assets/images/icon_USA.svg"
import iconTech from "../assets/images/icon_technology.svg"
import hurricanStrong from "../assets/images/hurricane_strong.svg"
import iconBuilt from "../assets/images/icon_built.svg"

export const WhyFenetex = () => {
    const [videoplay,setVideoplay]=React.useState(false)
  return (
    <section className="why-fenetex pt-80">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title-dark text-center mb-4">
                        <h2 className="heading">Why Fenetex?</h2>
                        <p>Fenetex is constantly pursuing innovation. We make the best products and then continue to make them better, keeping us ahead of the competition. <span>Whether you’re a homeowner, business owner, or dealer, you’ll have peace of mind before, during, and most importantly, long after our screens are installed.</span></p>
                    </div>
                </div> 
            </div>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="wf-col">
                                <img src={IconUSA} alt=""/>
                                <h3>Made in The USA</h3>
                                <p>Made in USA of U.S. and imported parts.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="wf-col">
                                <img src={iconTech} alt=""/>
                                <h3>Best Technology</h3>
                                <p>We have been revolutionizing the retractable screen industry for more than a decade.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="wf-col">
                                <img src={hurricanStrong} alt=""/>
                                <h3>Hurricane Strong</h3>
                                <p>Our MagForce tracks and ad- vanced hybrid ballistic fabrics are rated for the High Velocity Hurricane Zone and 185 MPH wind zones.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="wf-col">
                                <img src={iconBuilt} alt=""/>
                                <h3>Built to Last</h3>
                                <p>Marine-grade materials that resist corrosion, rust, and screen failure.</p>
                            </div>
                        </div>
                    </div>
                </div>          
            </div>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="video-wrapper">
                        {videoplay?(
                            <div id="video-container">
                                <iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Fenetex" width="640" height="360" src="https://www.youtube.com/embed/9St3T3fCXU4?controls=0&amp;autoplay=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Ffenetex.pisego.com&amp;widgetid=1" id="widget2"></iframe>
                            </div>
                        ):(
                            <img src={require("../assets/images/why-video-cover.jpg")} onClick={()=>setVideoplay(true)} id="play-button" alt=""/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
