import React from 'react';

export const TotalControl = (props) => {
  return (
    <div>
        <section className="total-control-sec py-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 offset-lg-1 text-align-end">
                        <img src={props.dataset.image} className="" width="82%" alt=""/>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-title-light mt-4 mb-4">
                            <h3 className="heading total-control">{props.dataset.title}</h3>
                        </div>
                        <p className='pb-3'>{props.dataset.desc}</p>
                        <div className="accordion accordion-flush" id="total_accordion">
                            {props.dataset.features.map((data,index)=>(
                                <div className="accordion-item light-accordian" key={index}>
                                    <h3 className="accordion-header">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={"#"+data.slug}>
                                        {data.title}
                                    </button>
                                    </h3>
                                    <div id={data.slug} className="accordion-collapse collapse" data-bs-parent="#feature_accordion">
                                    <p className="accordion-body">{data.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>      
        </section>
    </div>
  )
}
