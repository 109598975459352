import React, { useState,useContext } from "react";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";

import { Topbar } from "../Components/Topbar";
import "../Styles/commercial.css";
import { Navigation, A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ArrowButton from "../Components/ArrowButton";
import IconNotes from "../assets/icons/icon_notes.svg";
import IconTick from "../assets/icons/icon_tick.svg";
import IconSetting from "../assets/icons/icon_setting.svg";
import IconBox from "../Components/IconBox";
import Helmet from 'react-helmet';
import AppContext from '../AppContext';
import { Link } from "react-router-dom";
import customRoute from "../Components/CustomRoutes";

const Commercial = () => {
  const [isOpen, setIsOpen] = useState([false, false, false]);
  const {dealer,dealerData,dealerStatus} = useContext(AppContext)

  const toggleCollapse = (index) => {
    console.log("toggleCollapse", toggleCollapse);
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  return (
    <div className="commercial-page">
      {dealerStatus&&(<Helmet>
        <title>Screens for Commercial Use {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
        <meta name='description' content={`${dealerData?.meta_tags.commercial}`} />
      </Helmet>)}
      <header className='header-bar'>
        <Topbar dealeropp={true} />
        <Header />
      </header>
      <section className="banner-container">
        <div className="container">
          <div className="row">
            <div className="hero col-md-12">
              <div className="header-section">
                <h2 className="commcial-heading">
                  Commercial <br /> Retractable Screens
                </h2>
                <div className="hero-para">
                  <p className="">
                    {" "}
                    With Fenetex Retractable Screens, your investment is
                    protected, and your customers will enjoy the ambiance you’ve
                    worked so hard to create.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slider commercial-slider">
        <ArrowButton />
        <div className="">
          <div className="swiper-container-wrap eael-logo-carousel-wrap">
            <Swiper
              modules={[Navigation, A11y, Autoplay, Pagination]}
              spaceBetween={10}
              slidesPerView={3}
              autoHeight
              autoplay={{
                delay: 2500,
              }}
              navigation={{
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
              }}
              loop={true}
              speed={1000}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-3.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-4.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-5.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-6.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="eael-lc-logo-wrap">
                  <div className="eael-lc-logo">
                    <img
                      decoding="async"
                      src="http://fenetex.pisego.com/wp-content/uploads/2024/01/mg-7.png"
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section className="testimonial-section">
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="trusted-content">
                <div className="trusted-title">
                  <h2>Trusted by some of the best companies</h2>
                </div>
                <div className="testimonial-logos">
                  <img src={require("../assets/images/companies.png")} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="warranty-section">
        <div className="container warranty-section-bg">
          <div className="row">
            <div className="">
              <div className="warranty-img">
              <img src={require("../assets/images/warranty.png")} style={{maxWidth: 160 + 'px'}} alt="" />
                <div className="d-flex ">
                  <div className="warranty-heading">
                    <h1 className="warranty-heading-title">
                      Why Fenetex Screens?
                    </h1>
                  </div>

                  <div className="warranty-para">
                    <p className="warranty-para-title">
                      With Fenetex, you’re not just investing in commercial
                      solutions; you’re securing the most advantageous benefits
                      available in the market, specially designed to elevate
                      your business and leave a lasting impression on your
                      guests. Embrace the Fenetex difference and let us help you
                      create unforgettable outdoor environments!
                    </p>
                  </div>
                </div>

                <div className="row warranty-block">
                  <div className="first-warranty-block">
                    <div>
                      <span className="icon">
                        <img src={IconNotes} alt="" />
                      </span>
                    </div>
                    <div className="icon-box-content mt-4">
                      <h3 className="icon-box-title">
                        <span>Lifetime Warranty </span>
                      </h3>
                      <p className="icon-box-description">
                        The only company to offer a lifetime warranty, Fenetex
                        Retractable Screens showcase enduring excellence with
                        every use.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="second-warranty-block">
                    <div>
                      <span className="icon ">
                        <img src={IconTick} alt="" />
                      </span>
                    </div>
                    <div className="icon-box-content mt-4">
                      <h3 className="icon-box-title">
                        <span>Built to Last </span>
                      </h3>
                      <p className="icon-box-description">
                        Your satisfaction and happiness in our product will
                        always be our top priority, which is why our screes are
                        built to the highest standards.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="third-warranty-block">
                    <div>
                      <span className="icon ">
                        <img src={IconSetting} alt="" />
                      </span>
                    </div>
                    <div className="icon-box-content mt-4">
                      <h3 className="icon-box-title">
                        <span>Best Technology</span>
                      </h3>
                      <p className="icon-box-description">
                        Fenetex offers revolutionary retractable screens using
                        our patented Quiet Spring Technology
                        at the core of our One-Track system.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="warranty-img-mobile">
              <img src={require("../assets/images/warranty.png")} style={{maxWidth: 150 + 'px'}} alt="" />
                <div className="lg:d-flex ">
                  <div className="warranty-heading">
                    <h1 className="warranty-heading-title">
                      Why Fenetex Screens?
                    </h1>
                  </div>

                  <div className="warranty-para">
                    <p className="warranty-para-title">
                      With Fenetex, you’re not just investing in commercial
                      solutions; you’re securing the most advantageous benefits
                      available in the market, specially designed to elevate
                      your business and leave a lasting impression on your
                      guests. Embrace the Fenetex difference and let us help you
                      create unforgettable outdoor environments!
                    </p>
                  </div>
                </div>

                <div className="warranty-block">
                  <IconBox
                    title="Lifetime Warranty"
                    description="The only company to offer a lifetime warranty, Fenetex Retractable Screens showcase enduring excellence with every use."
                    icon={IconNotes}
                    isOpen={isOpen[0]}
                    toggle={() => toggleCollapse(0)}
                  />
                  <IconBox
                    title="Built to Last"
                    description="Your satisfaction and happiness in our product will always be our top priority, which is why our screens are built to the highest standards."
                    icon={IconTick}
                    isOpen={isOpen[1]}
                    toggle={() => toggleCollapse(1)}
                  />
                  <IconBox
                    title="Best Technology"
                    description="Fenetex offers revolutionary retractable screens using our patented Quiet Spring Technology at the core of our One-Track system."
                    icon={IconSetting}
                    isOpen={isOpen[2]}
                    toggle={() => toggleCollapse(2)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-offering">
        <div className="container product-offering-bg">
          <div className="row">
            <div className="product-offering-heading">
              <h2>Products Offering</h2>
            </div>

            <div className="product-offering-content">
              <p>
                We offer a diverse range of screen fabrics, track types, motor
                options, and mounting solutions to customize a solution
                perfectly tailored to your business needs. Whether you’re
                looking to enhance your storefront, optimize your outdoor dining
                area, or create a comfortable environment for your clients,
                Fenetex has the right product for you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="product p-desktop-view">
        <div className="col-sm-12  product-desktop-view">
          <div className="col-sm-3 product-img">
            <div className="product-img1"></div>
            <div className="overlay-section">
              <h2 className="overlay-section-title">Insect Screen </h2>
              <div className="overlay-description">
                These screens fortify your space, making it impenetrable to determined insects. They retract on insect-free days but effectively stop insects from advancing.
              </div>

              <div className="overlay-button">
                <Link to={customRoute.insect_screen.url} className="overlay-button-anchor"> {">"} </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-3 product-img">
            <div className="product-img2"></div>
            <div className="overlay-section">
              <h2 className="overlay-section-title">Clear Vinyl </h2>
              <div className="overlay-description">
                These screens maintain the wide-open view you want from your outdoor space, while protecting you from plunging temps or pouring rain.
              </div>

              <div className="overlay-button">
                <Link to={customRoute.clear_vinyl.url} className="overlay-button-anchor"> {">"} </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-3 product-img">
            <div className="product-img3"></div>
            <div className="overlay-section">
              <h2 className="overlay-section-title">Shade Screen</h2>
              <div className="overlay-description">
                These screens block to up 97% of the sun's rays while also making a substantial dent in your energy costs.
              </div>

              <div className="overlay-button">
                <Link to={customRoute.shade_screen.url} className="overlay-button-anchor"> {">"} </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-3 product-img">
            <div className="product-img4"></div>
            <div className="overlay-section">
              <h2 className="overlay-section-title">Hurrican Screen </h2>
              <div className="overlay-description">
                Our hurricane screens are the backbone of our whole product line. We developed them to be strong, attractive, UV-resistant, and ultimately reliable.
              </div>

              <div className="overlay-button">
                <Link to={customRoute.hurricane.url} className="overlay-button-anchor"> {">"} </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-mobile-view">
        <div className="col-sm-12 product-mobile-view">
          <Swiper
            modules={[Navigation, A11y, Pagination]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            autoplay={{ delay: 5000 }}
            dir="ltr"
            slidesPerView={1}
            className="product-swiper"
            loop={true}
          >
            <SwiperSlide className="repeater-item-2a3adaf swiper-slide">
              <div className="custom-slide-content">
                <div className="swiper-slide-bg"></div>
                <div className="swiper-overlay-bg"></div>

                <a
                  href="/products/insect-screens/"
                  className="swiper-slide-inner"
                >
                  <div className="swiper-slide-contents animated fadeInUp">
                    <div className="slide-heading">Insect Screens</div>
                    <div className="slide-description">
                      These screens fortify your space, making it impenetrable
                      to determined insects. They retract on insect-free days
                      but effectively stop insects from advancing.
                    </div>
                    <Link to={customRoute.insect_screen.url}><div className="slide-button">See product</div></Link>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className="repeater-item-2a3adaf swiper-slide">
              <div className="custom-slide-content">
                <div className="swiper-slide-bg-2"></div>
                <div className="swiper-overlay-bg"></div>

                <a
                  href="/products/weather-screens/"
                  className="swiper-slide-inner"
                >
                  <div className="swiper-slide-contents animated fadeInUp">
                    <div className="slide-heading">Clear Vinyl</div>
                    <div className="slide-description">
                      These screens' generously proportioned clear inserts
                      maintain the wide-open view you want from your outdoor
                      space, while protecting you from plunging temps or pouring
                      rain.
                    </div>
                    <Link to={customRoute.clear_vinyl.url}><div className="slide-button">See product</div></Link>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className="repeater-item-2a3adaf swiper-slide">
              <div className="custom-slide-content">
                <div className="swiper-slide-bg-3"></div>
                <div className="swiper-overlay-bg"></div>

                <a
                  href="/products/shade-screens/"
                  className="swiper-slide-inner"
                >
                  <div className="swiper-slide-contents animated fadeInUp">
                    <div className="slide-heading">Shade Screens</div>
                    <div className="slide-description">
                      These screens block 97% of the sun's rays while also
                      making a substantial dent in your energy costs.
                    </div>
                    <Link to={customRoute.shade_screen.url}><div className="slide-button">See product</div></Link>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className="repeater-item-2a3adaf swiper-slide">
              <div className="custom-slide-content">
                <div className="swiper-slide-bg-4"></div>
                <div className="swiper-overlay-bg"></div>

                <a
                  href="/products/dual-screens/"
                  className="swiper-slide-inner"
                >
                  <div className="swiper-slide-contents animated fadeInUp">
                    <div className="slide-heading">Hurricane Screens</div>
                    <div className="slide-description">
                      Our hurricane screens are the backbone of our whole
                      product line. We developed them to be strong, attractive,
                      UV-resistant, and ultimately reliable.
                    </div>
                    <Link to={customRoute.hurricane.url}><div className="slide-button">See product</div></Link>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
          <ArrowButton />
        </div>
      </section>
      <section className="py-5 design-section design-desktop-view">
        <div className="design">
          <div className="design-img"></div>
          <div className="design-mobile-img"></div>
          <div className="design-content">
            <h2>
              We design and manufacture the best motorized screens possible to
              make your business better.
            </h2>
          </div>
        </div>
        <div className="technology ">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="">
                <div className="tech-heading-title">
                  <span>Technology</span>
                </div>

                <div className="tech-sub-heading">
                  <h2>Fenetex's Patented Screen Technology Solutions</h2>
                </div>

                <div className="tech-main-content-para">
                  <p>
                    Each Fenetex product line is meticulously developed and
                    rigorously tested to meet the stringent demands of our
                    commercial clientele. In the realm of screens, we understand
                    that one size does not fit all, especially when it comes to
                    solving unique challenges faced by businesses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="">
              {/* <div className="business-img d-flex justify-content-center">
                <a>
                  <img
                    loading="lazy"
                    decoding="async"
                    width="200"
                    height="120"
                    src="http://fenetex.pisego.com/wp-content/uploads/2024/02/Logo-megaforce-1.png"
                    className="mobile-view"
                    alt=""
                  />
                </a>
              </div> */}

              <div className="business-img d-flex flex-col justify-content-center">
                <a>
                  <img
                    loading="lazy"
                    decoding="async"
                    width="200"
                    height="120"
                    src="http://fenetex.pisego.com/wp-content/uploads/2024/02/Logo_onetrack-1.png"
                    className="mobile-view"
                    alt=""
                  />
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
          
        </div>
      </section>

      <section className="have-project">
        <div className="have-project-container">
          <div className="have-project-heading">
            <h2 className="text-center">Have a Project in Mind?</h2>
          </div>
          <div className="have-project-para">
            <p>
              Provide your customers with the best outdoor experience while<br/>protecting your investment.
            </p>
          </div>
          <div className="have-project-button">
            <Link
              to={customRoute.contact.url}
              className="elementor-button-link elementor-button elementor-size-sm"
              role="button"
            >
              <span className="elementor-button-content-wrapper">
                <span className="elementor-button-text">Get in Touch</span>
              </span>
            </Link>
          </div>
        </div>
      </section>
      <div className="footer">
        <Footer get_in_touch={false} />
      </div>
    </div>
  );
};

export default Commercial;
