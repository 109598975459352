import {Header} from '../Components/Header';
import {Topbar} from '../Components/Topbar';
import React,{useContext,useRef} from 'react';
import { Footer } from '../Components/Footer';
import '../Styles/style.css';
import IconUSA from "../assets/images/icon_USA.svg"
import iconTech from "../assets/images/icon_technology.svg"
import hurricanStrong from "../assets/images/hurricane_strong.svg"
import iconBuilt from "../assets/images/icon_built.svg"
import { ContactInfo } from '../Components/ContactInfo';
import { CompanyBadge } from '../Components/CompanyBadge';
import { Estimateform } from '../Components/Estimateform';
import AppContext from '../AppContext';
import { useNavigate } from 'react-router';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { ProductsSection } from '../Components/ProductsSection';
import { UnmatchedExSlider } from '../Components/UnmatchedExSlider';
import { CustomiseOption } from '../Components/CustomiseOption';
import axios from 'axios';
import { WhyFenetex } from '../Components/WhyFenetex';
import Helmet from 'react-helmet';

const Home=()=>{
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
    const navigate = useNavigate()
    const ref = useRef(null);
    const refTop = useRef(null);

    const handleClick = (type) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleClickTop = (type) => {
        refTop.current?.scrollIntoView({ behavior: 'smooth' });
    }
    
    React.useEffect(()=>{
        
    },[])
    return (
        <div>
            {dealerStatus&&(<Helmet>
                <title>{`${dealer && dealer.company_name ? dealer.company_name : "" }`} - Authorized Dealer of Fenetex Screens</title>
                <meta name='description' content={`${dealerData?.meta_tags?.home}`} />
            </Helmet>)}
            <header className='header-bar'>
                <Topbar />
                <Header />
            </header>
            <section className="home-banner-section dealer-banner-section" ref={refTop}>
                <div className="banner-bg">
                <img src={require("../assets/images/banner-scaled.jpg")} className="img-fluid banner-img" alt="" />
                    <div className="home-banner-overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="banner-content">
                                    {/* <h6 className="text-white">Authorised Dealer</h6> */}
                                    <div className="banner-title">
                                        <h1>Upgrade Your Home with <span>Fenetex Motorized Screens!</span> Expert Installation by our Authorized Dealer <span>{dealerData?.name}</span></h1>
                                    </div>
                                    <div className="banner-left-form mt-3">
                                        <h3 className='text-center'>Transform Your Home Today!</h3>
                                        <Estimateform dealer={dealer} />
                                    </div>
                                    <img src={require("../assets/images/warranty.png")} width={"120"} className="mt-2" alt="" /> 
                                    <img className="bbb-logo" onClick={()=>window.location.replace("https://www.bbb.org/us/fl/jacksonville/profile/window-screens/fenetex-corportation-0403-235974128")} src={require("../assets/images/logofooter.png")} alt="" />     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </section>

            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 mb-3 mb-sm-0">
                            <img src={require("../assets/images/about.png")} className="img-fluid" alt=""/>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-left">
                                <div className="section-title-light">
                                    <h4 className="sub-heading">About Your Fenetex Dealer</h4>
                                    <h3 className="heading">Why Choose {dealer.company_name} as Your Innovative Service Provider?</h3>
                                </div>
                                <p>
                                    {dealerData?dealerData?.about?.map((s,i)=>{
                                        return(
                                            <>
                                                {s}{dealerData?.about.length!==i+1? <><br/><br/></> :""}
                                            </>
                                        )
                                    }):(
                                        <p>{dealer.company_name} is your go-to dealer for upgrading outdoor spaces and protecting your home from storms. We are proud to offer top-of-the-line products from Fenetex, Struxure Pergola, Cover Your Pergola Inc., Sun Pro Awnings, Anchor Outdoor, Hurricane Fabric, AHT Shutters, and Unique Technology Custom Screen Doors.
                                        <br/><br/>We are your one-stop shop to update your outdoor space. We deliver the highest quality installation and customer service possible. Based out of St. Augustine, we service Georgia to Port Saint Lucie. We have a professional team of skilled people to customize your perfect outdoor oasis.</p>
                                    )}
                                </p>
                                <a className="btn btn-secondary" onClick={handleClickTop}>Request a FREE Estimate</a>
                            </div>
                        </div>
                    </div>
                </div>      
            </section>

            <ProductsSection />

            <section className="home-about my-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-left mb-4">
                                <div className="section-title-light">
                                    <h2 className="heading">Revolutionize your outdoor space with Fenetex's retractable screens. Elevate comfort</h2>
                                </div>
                                <p>Discover unparalleled convenience and security with Fenetex screens. Our screens, renowned for their easy installation and adjustment on any structure, seamlessly blend strength, reliability, and durability. Fenetex's retractable screens cater to diverse needs—from patio and porch to screen doors, bug screens, folding doors, aluminum doors, patio doors, sliding doors, swing doors, swinging doors, motorized retractable screens, and retractable impact screens. Effortlessly hidden yet deployable with a touch, they integrate into smart home systems for remote operation, offering a cutting-edge solution tailored to your lifestyle.</p>
                                <div className='section-btn'><a href="#" className="btn btn-accent mt-3">More Info</a></div>
                            </div>
                        </div> 
                        <div className="col-lg-6 col-md-12">
                            <img src={require("../assets/images/outdoor.jpg")} className="img-fluid" alt=""/>
                        </div>              
                    </div>
                </div>      
            </section>

            <CompanyBadge handleClickTop={handleClickTop} />

            <WhyFenetex />

            <section className="request-estimate">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h3 className="text-end">Request a FREE estimate today</h3>
                        </div>
                        <div className="col-lg-5 text-center">
                            <div href="" onClick={handleClickTop}  className="btn btn-accent">Get a FREE Estimate</div>
                        </div>
                    </div>
                </div>
            </section>
            <CustomiseOption handleClick={handleClickTop} />
            {/* <UnmatchedExSlider /> */}
            <div ref={ref}>
                <ContactInfo />
            </div>
            <Footer dealer={true} dealerData={dealerData} handleClick={handleClick} handleClickTop={handleClickTop} />     
        </div>
    )
}
export default Home
